import {
  postToItemServer,
  postToZCYShareServer,
  postToBaseInfoServer,
} from "./common";
/**
 * 获得商品类目(公共)
 */
export async function getCats() {
  return await postToBaseInfoServer("/common/cat/list");
}
/**
 * 获得类目详情(公共)(包含类目属性)
 */
export async function getCatDetail(catId){
    return await postToBaseInfoServer('/common/cat/get/'+catId);
}
/**
 * 获得类目详情(针对工厂)(包含类目属性)
 */
export async function getFacCatDetail(catId){
  return await postToBaseInfoServer(`/front/customer/cat/attr/list/${catId}`,{hasValue:true});
}
/**
 * 中台-工厂-属性-列表
 */
export async function getAttrs(catid,obj) {
  return await postToBaseInfoServer(`/front/customer/cat/spu/attr/list/${catid}`,obj);
}
/**
 * 获取商品风格列表(公共)
 */
export async function getStyles() {
  return await postToBaseInfoServer("/common/style/list");
}
/**
 * 获取商品材质列表(公共)
 */
export async function getMaterials() {
  return await postToBaseInfoServer("/common/material/list");
}
/**
 * 获取商品颜色列表(公共)
 */
export async function getColors() {
  return await postToBaseInfoServer("/common/color/list");
}
/**
 * 获取商品材质列表(只针对sku)
 */
export async function materialList(obj) {
  return await postToBaseInfoServer("/front/customer/material/list",obj);
}
/**
 * 材质属性列表排序(只针对sku)
 */
export async function materialSort(obj) {
  return await postToBaseInfoServer('/front/customer/material/sort', obj);
}
/**
* 新增材质属性(只针对sku)
*/
export async function materialAdd(obj) {
  return await postToBaseInfoServer('/front/customer/material/add', obj);
}
/**
* 编辑材质属性(只针对sku)
*/
export async function materialEdit(obj) {
  return await postToBaseInfoServer('/front/customer/material/edit', obj);
}
/**
* 删除材质属性(只针对sku)
*/
export async function materialDel(id) {
  return await postToBaseInfoServer(`/front/customer/material/del/${id}`);
}
/**
 * 获取商品颜色列表(只针对sku)
 */
export async function colorList(obj) {
  return await postToBaseInfoServer("/front/customer/color/list",obj);
}
/**
 * 颜色属性列表排序(只针对sku)
 */
export async function colorSort(obj) {
  return await postToBaseInfoServer('/front/customer/color/sort', obj);
}
/**
* 新增颜色属性(只针对sku)
*/
export async function colorAdd(obj) {
  return await postToBaseInfoServer('/front/customer/color/add', obj);
}
/**
* 编辑颜色属性(只针对sku)
*/
export async function colorEdit(obj) {
  return await postToBaseInfoServer('/front/customer/color/edit', obj);
}
/**
* 删除颜色属性(只针对sku)
*/
export async function colorDel(id) {
  return await postToBaseInfoServer(`/front/customer/color/del/${id}`);
}
/**
 * 类目属性值列表
 */
export async function catAttrValueList(attrid,obj) {
  return await postToBaseInfoServer(`/front/customer/cat/attr/value/list/${attrid}`,obj);
}
/**
* 类目属性值列表排序
*/
export async function catAttrValueSort(obj) {
  return await postToBaseInfoServer(`/front/customer/cat/attr/value/sort`,obj);
}
/**
* 新增类目属性值
*/
export async function catAttrValueAdd(obj) {
  return await postToBaseInfoServer('/front/customer/cat/attr/value/add', obj);
}
/**
* 编辑类目属性值
*/
export async function catAttrValueEdit(obj) {
  return await postToBaseInfoServer('/front/customer/cat/attr/value/edit', obj);
}
/**
* 删除类目属性值
*/
export async function catAttrValueDel(id) {
  return await postToBaseInfoServer(`/front/customer/cat/attr/value/del/${id}`);
}
/* ------------------------列表商品分类（弃用）--------------------------- */
/**
 * 列表商品分类
 * @param {*} obj
 */
export async function getSets(obj) {
  return await postToItemServer("/product-set/list-set", obj);
}
/**
 * 新增商品分类
 * @param {*} obj
 */
export async function addSet(obj) {
  return await postToItemServer("/product-set/add-set", obj);
}
/**
 * 修改商品分类
 * @param {*} obj
 */
export async function updateSet(obj) {
  return await postToItemServer("/product-set/update-set", obj);
}
/**
 * 删除商品分类
 * @param {*} obj
 */
export async function delSet(obj) {
  return await postToItemServer("/product-set/del-set", obj);
}
/* ------------------------自定义商品分类--------------------------- */
/**
 * 列表自定义商品分类
 * @param {*} obj
 */
export async function classifyList(compId,obj) {
  return await postToBaseInfoServer(`/common/orgcat/list/${compId}`, obj);
}
// 新增
export async function orgcatAdd(obj) {
  return await postToBaseInfoServer("/front/orgcat/add", obj);
}
// 修改
export async function orgcatEdit(obj) {
  return await postToBaseInfoServer("/front/orgcat/edit", obj);
}
// 移动
export async function orgcatMove(obj) {
  return await postToBaseInfoServer("/front/orgcat/move", obj);
}
// 删除
export async function orgcatDel(obj) {
  return await postToBaseInfoServer("/front/orgcat/del", obj);
}
// 排序
export async function orgcatSort(obj) {
  return await postToBaseInfoServer("/front/orgcat/sort", obj);
}


/*-------------------------------------------------------*/
/**
 * 列表商品分类下的商品
 * @param {*} obj
 */
export async function listSetProduct(obj) {
  return await postToItemServer("/product-set/list-set-product", obj);
}
/**
 * 从商品分类移除商品
 * @param {*} obj
 */
export async function removeFromSet(obj) {
  return await postToItemServer("/product-set/remove-from-set", obj);
}
/**
 * 移动商品到其他商品分类
 * @param {*} obj
 */
export async function moveFromSet(obj) {
  return await postToItemServer("/product-set/move-to-set", obj);
}

